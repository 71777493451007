import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import UserReducer from "../reducers/UserReducer";
import {
  LOGIN,
  LOGOUT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  REFRESH_LOGIN,
  EDITAR_USUARIO,
  GUARDAR_USUARIO,
  SET_PROPIEDAD_USER,
} from "../types";
import UserService from "../services/UserService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { hideModal } from "../utils";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
};

export const AuthContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  function setupInterceptors() {
    AuthService.setupInterceptors(refreshLogin, handleError);
  }

  function refreshLogin() {
    dispatch({ type: REFRESH_LOGIN });
  }

  function handleError(error) {
    if(error.message && error.message !== null) {
      return alert(error.message);
    }
    alert(error);
  }

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (user) {
          UserService.getCurrentUser()
            .then((res) => {
              let { user } = res.data;
              dispatch({
                type: LOGIN,
                payload: user,
              });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status !== 400) {
                  AuthService.signOut();
                  return alert(error.toString());
                }
              }
              alert(error);
              AuthService.signOut();
            });
        } else {
          alert(
            "Ya tienes cuenta en Agave Brands pero la contraseña es incorrecta. Por favor, intenta de nuevo"
          );
          dispatch({ type: HIDE_SPINNER });
          AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("La contraseña es incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error);
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      () => {
        UserService.getCurrentUser()
          .then((res) => {
            let { user } = res.data;
            dispatch({
              type: LOGIN,
              payload: user,
            });
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            alert(error);
            dispatch({ type: HIDE_SPINNER });
          });
      },
      (error) => {
        if (error) {
          alert(error);
          signOut();
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/login");
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function signUp(nombre, correo, password, telefono) {
    dispatch({ type: SHOW_SPINNER });
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!correo || correo === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    AuthService.signUp(correo, password)
      .then((user) => {
        const uid = user.user.uid;
        UserService.postSignUp({
          name: nombre,
          email: correo,
          phone: telefono,
          uid,
        }).then(() => {
          signIn(correo, password);
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Ya tienes una cuenta en Agave Brands App. La contraseña que ingresaste es incorrecta."
            );
          }
        }
        if (error.message === "EMAIL_EXISTS") {
          return alert(
            "Ya existe una cuenta con ese correo electrónico, inicia sesión."
          );
        }
        alert(error.toString());
      });
  }

  function getCurrentUser() {
    UserService.getCurrentUser()
      .then((res) => {
        const { user } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: LOGIN, payload: user });
        AuthService.setupInterceptors(refreshLogin);
      })
      .catch((err) => {
        dispatch({ type: HIDE_SPINNER });
        if (err.response.status === 400) {
          signOut("/auth");
        }
      });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idFile") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value) {
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
      }
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Password reset email sent.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          UserService.recoverPassword(email).then(() => {
            success("Password reset email sent.");
          });
        } else {
          alert("There was an error resetting your password. Try again later.");
        }
      });
  }

  function updateUser(usuario) {
    const data = { ...usuario };
    delete data.file;
    delete data.uid;
    delete data.activo;
    UserService.updateCurrentUser(data)
      .then(() => {
        clearModal();
        getCurrentUser();
        success("Profile saved.");
      })
      .catch((error) => {
        alert(error);
      });
  }

  function deleteCustomer() {
    dispatch({ type: SHOW_SPINNER });
    UserService.deleteCurrentUser()
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Cuenta eliminada.");
        AuthService.signOut();
        navigate("/");
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  }

  function saveUserDevice(user_device) {
    dispatch({ type: SHOW_SPINNER });
    let service = UserService.putUserDevice;
    if (isNaN(user_device.user_device_id)) {
      service = UserService.postUserDevice;
    }
    service(user_device)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Device saved.");
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        getCurrentUser,
        cancelEdit,
        userLoggedIn,
        updateUser,
        editarUsuario,
        saveUserDevice,
        deleteCustomer,
        recoverPassword,
        setPropiedadUser,
        setupInterceptors,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
