import React, { useContext } from "react";
import BirthdateInput from "../common/BirthdateInput";
import { UsersContext } from "../../context/UsersContext";
import SelectUserType from "../global/SelectUserType";

const UserForm = ({ project_id, handleCancel, disableEmail }) => {
  const { user, setPropertyUser, saveUser } = useContext(UsersContext);
  const { name, last_name, email, phone, birthdate, user_type_id } = user;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (project_id && project_id !== null) {
      user.project_id = project_id;
    }
    saveUser(user);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Name:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={name}
        onChange={(e) => setPropertyUser("name", e.target.value)}
      />
      <label>Last Name:</label>
      <input
        type="text"
        className="form-control mb-3"
        value={last_name}
        onChange={(e) => setPropertyUser("last_name", e.target.value)}
      />
      <label>Email:</label>
      <input
        type="email"
        value={email}
        disabled={disableEmail}
        className="form-control mb-3"
        onChange={(e) =>
          setPropertyUser("email", String(e.target.value).trim())
        }
      />
      <label>Birthdate:</label>
      <BirthdateInput
        value={birthdate}
        modifier={(value) => setPropertyUser("birthdate", value)}
      />
      <label>Phone</label>
      <input
        type="tel"
        className="form-control mb-3"
        value={phone}
        onChange={(e) => setPropertyUser("phone", e.target.value)}
      />
      <SelectUserType
        title="Privileges"
        value={user_type_id}
        modifier={(value) => setPropertyUser("user_type_id", value)}
      />
      <button type="submit" className="btn btn-primary w-100 mb-3">
        Save
      </button>
      <button
        type="button"
        className="btn btn-link text-secondary w-100"
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  );
};

export default UserForm;
