import React, { useState, useContext, useEffect } from "react";
import { InvoicesContext } from "../../context/InvoicesContext";
import { OrdersContext } from "../../context/OrdersContext";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";
import moment from "moment";

const InvoiceForm = ({
  order_id,
  project_id,
  handleCancel,
  handleDelete,
  handleCallback,
}) => {
  const [isPercent, setIsPercent] = useState(false);
  const [maxPercent, setMaxPercent] = useState(null);
  const [percent, setPercent] = useState(100);
  const { order } = useContext(OrdersContext);

  const { invoice, saveInvoice, setPropertyInvoice } =
    useContext(InvoicesContext);

  useEffect(() => {
    if (percent > 100) {
      setPercent(100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  useEffect(() => {
    if (isPercent) {
      handleMaxPercent();
      if (order && order !== null) {
        if (order.currency && order.currency !== null) {
          setPropertyInvoice("currency", order.currency);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, isPercent]);

  useEffect(() => {
    if (percent > 0 && order && order !== null) {
      setPropertyInvoice(
        "amount",
        ((parseFloat(percent) / 100) * parseFloat(order.amount)).toFixed(2)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  useEffect(() => {
    if (maxPercent !== null) {
      setPercent(maxPercent);
    }
  }, [maxPercent]);

  const handleMaxPercent = () => {
    if (Array.isArray(order.invoices) && maxPercent === null) {
      if (order.invoices.length > 0 && order.products.length > 0) {
        let totalInvoices = 0.0;
        order.invoices.forEach((invoice) => {
          totalInvoices += parseFloat(invoice.amount);
        });
        let totalProducts = 0.0;
        order.products.forEach((product) => {
          totalProducts +=
            parseFloat(product.quantity) * parseFloat(product.price);
        });
        const currentMax = 100 - (totalInvoices / totalProducts) * 100;
        setMaxPercent(currentMax);
      } else {
        setMaxPercent(100);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (order_id && order_id !== null) {
      invoice.order_id = order_id;
    }
    if (project_id && project_id !== null) {
      invoice.project_id = project_id;
    }
    saveInvoice(project_id, invoice, handleCallback);
  };

  const handlePercent = (e) => {
    const current = e.target.value;
    if (current <= maxPercent) setPercent(current);
  };

  const renderPercent = () => {
    if (isPercent) {
      return (
        <div>
          <label>Percentage %</label>
          <input
            step=".01"
            type="number"
            value={percent}
            onChange={handlePercent}
            className="form-control mb-3"
          />
        </div>
      );
    }
  };

  let { min_date, max_date } = invoice;

  min_date = moment(min_date).utc().format("YYYY-MM-DD");
  max_date = moment(max_date).utc().format("YYYY-MM-DD");

  return (
    <form onSubmit={handleSubmit} className="container px-0">
     <label>Description</label>
      <input
        type="text"        
        className="form-control mb-3"
        value={getValue(invoice, "description")}
        onChange={(e) => setPropertyInvoice("description", e.target.value)}
      />
      {order_id && order_id !== null && (
        <label className="d-flex align-items-center mb-3">
          <ReactSwitch checked={isPercent} onChange={setIsPercent} />
          <span className="ms-2">Set on Percentage </span>
        </label>
      )}
      {renderPercent()}
      <label>Amount</label>
      <input
        type="number"
        disabled={isPercent}
        className="form-control mb-3"
        value={getValue(invoice, "amount")}
        onChange={(e) => setPropertyInvoice("amount", e.target.value)}
      />
      <label>Currency</label>
      <select
        disabled={isPercent}
        className="form-control mb-3"
        value={getValue(invoice, "currency")}
        onChange={(e) => setPropertyInvoice("currency", e.target.value)}
      >
        <option>MXN</option>
        <option>USD</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      <label>Status</label>
      <select
        value={invoice.status}
        className="form-control mb-3"
        onChange={(e) => setPropertyInvoice("status", e.target.value)}
      >
        <option value="pending">Pending</option>
        <option value="completed">Completed</option>
        <option value="cancelled">Cancelled</option>
        <option value="refunded">Refunded</option>
      </select>
      <label>Starting From</label>
      <input
        type="date"
        className="form-control mb-3"
        value={min_date !== null ? min_date : ""}
        onChange={(e) => setPropertyInvoice("min_date", e.target.value)}
      />
      <label>Due Date</label>
      <input
        type="date"
        className="form-control mb-3"
        value={max_date !== null ? max_date : ""}
        onChange={(e) => setPropertyInvoice("max_date", e.target.value)}
      />
      <button className="btn btn-primary mb-4 w-100">Save</button>
      <button
        className="btn text-accent h6 mb-4 text-center d-block w-100"
        onClick={handleCancel}
        type="button"
      >
        Cancel
      </button>
      {typeof handleDelete === "function" && (
        <button
          type="button"
          className="btn text-danger h6 text-center d-block w-100"
          onClick={() => handleDelete(invoice)}
        >
          Delete Invoice
        </button>
      )}
    </form>
  );
};

export default InvoiceForm;
