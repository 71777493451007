import React, { useContext } from "react";
import IconItem from "../global/IconItem";
import { formatMonto } from "../../utils";
import InvoiceItem from "../invoices/InvoiceItem";
import InvoiceForm from "../invoices/InvoiceForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { InvoicesContext } from "../../context/InvoicesContext";
import { getTotal } from "../../utils/invoices";

const OrderInvoices = ({
  order,
  order_id,
  invoices,
  project_id,
  handleCallback,
}) => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setInvoice, clearInvoice, createInvoice, deleteInvoice } =
    useContext(InvoicesContext);

  const handleCancel = () => {
    clearModal();
    clearInvoice();
  };

  const handleDeleteInvoice = (invoice) => {
    deleteInvoice(project_id, invoice.invoice_id, handleCallback);
  };

  const handleEditInvoice = (invoice) => {
    setInvoice(invoice);
    modalComponent(
      "Edit Invoice",
      <InvoiceForm
        order_id={order_id}
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
        handleDelete={handleDeleteInvoice}
      />
    );
  };

  const handleCreateInvoice = () => {
    createInvoice();
    modalComponent(
      "Add Invoice",
      <InvoiceForm
        order_id={order_id}
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
        handleDelete={handleDeleteInvoice}
      />
    );
  };

  const renderAmountError = () => {
    if (Array.isArray(invoices)) {
      const total = getTotal(invoices);
      if (total !== order.amount) {
        const diff = order.amount - total;
        return (
          <div className="row bg-light">
            <p className="text-danger mb-0 py-2 bold">
              Sum of invoices ${formatMonto(total)} does not match order total
              of ${formatMonto(order.amount)} {order.currency}.{" "}
              {diff < 0
                ? `You're over the total by $${diff * -1}.`
                : `You're missing $${diff}.`}
            </p>
          </div>
        );
      }
    }
  };

  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p>This order has no invoices.</p>;
      }
      return invoices.map((invoice) => (
        <div key={invoice.invoice_id} className="border-top">
          <InvoiceItem
            invoice={invoice}
            project_id={project_id}
            key={invoice.invoice_id}
            handleEdit={handleEditInvoice}
            hideButtons={[
              "ready",
              "approved",
              "in-progress",
              "completed",
            ].includes(order.status)}
          />
        </div>
      ));
    }
  };

  const renderButton = () => {
    if (
      (user.user_type_id > 3 && order.status === "quoted") ||
      user.user_type_id === 6
    ) {
      return (
        <button
          className="btn btn-sm text-primary"
          onClick={handleCreateInvoice}
        >
          <i className="fa fa-plus"></i>
        </button>
      );
    }
  };

  return (
    <div className="card pt-2 px-3 pb-0 mb-3 shadow-sm">
      <div className="row align-items-center">
        <div className="col-10">
          <IconItem name="Invoices" icon="file-invoice-dollar" />
        </div>
        <div className="col-2 text-end">{renderButton()}</div>
      </div>
      {renderInvoices()}
      {renderAmountError()}
      {Array.isArray(invoices) && (
        <div className="row py-2 bg-light">
          <div className="col-4">
            <b>Total</b>
          </div>
          <div className="col-8 bold">${formatMonto(getTotal(invoices))}</div>
        </div>
      )}
    </div>
  );
};
export default OrderInvoices;
