import React from "react";
import moment from "moment";
import { formatMonto } from "../../utils";
import OrderStatusBadge from "../order/OrderStatusBadge";

const InvoiceItem = ({ invoice, handleEdit }) => {
  const renderDueDate = () => {
    if (moment(invoice.max_date).isValid()) {
      return moment(invoice.max_date).utc().format("DD MMM YYYY");
    }
    return <span className="small text-muted">No Due Date</span>;
  };

  const renderDescription = () => {
    if (invoice.description !== null) {
      return <p className="mb-0">{invoice.description}</p>;
    }
    return <p className="small mb-0">No description</p>;
  };

  return (
    <div>
      <div className="row align-items-center my-2 py-3">
        <div className="col-1 pe-0">#{invoice.invoice_id}</div>
        <div className="col-4">{renderDueDate()}</div>
        <div className="col-4">
          ${formatMonto(invoice.amount)} {invoice.currency}
        </div>
        <div className="col-2">
          <OrderStatusBadge status={invoice.status} />
        </div>
        {typeof handleEdit === "function" && (
          <div className="col-1">
            <button
              onClick={() => handleEdit(invoice)}
              className="btn btn-sm text-primary"
            >
              <i className="fa fa-edit" />
            </button>
          </div>
        )}
      </div>
      {renderDescription()}
    </div>
  );
};

export default InvoiceItem;
